import {put, delay, takeEvery, select, call, all} from "redux-saga/effects";
import SettingsAction from "../settings/settingsAction";
import ProfilerAction from "./profilerAction";
import {apiAudioService, apiCaseService, apiJobsService, apiProfileService} from "../../config/api";
import {
  prepareAnalyzeContent,
  prepareFetchFriendRequest,
  transformActivityStat,
  transformContactInfoData,
  transformDeepWeb,
  transformFriendsData,
  transformFriendsStatData,
  transformGeneralStat,
  transformHistory,
  transformImageResults,
  transformLocations,
  transformPersonalInfoData,
  transformPersonProfileData,
  transformPostFilters,
  transformPostsData,
  transformPostsFilter,
  transformPostStat,
  transformPreferencesData,
  transformPsychoProfiles,
  transformSearchProgress, transformWebSearchResults
} from "./profilerTransformer";
import axios from "axios";
import config from "../../config/config";
import {authHeader} from "../../helpers/authHeader";
import ExportFactory from "../../services/Export/ExportFactory";
import {
  getProfilerContactInfo,
  getProfilerDeepWeb,
  getProfilerFriends, getProfilerGeneralStat,
  getProfilerImages,
  getProfilerLocations,
  getProfilerPerson,
  getProfilerPersonalInfo,
  getProfilerPosts, getProfilerPostStat,
  getProfilerPreferences, getProfilerPsychoProfile,
  getProfilerSearchProgress,
  getProfilerSynopsis,
  getProfilerWebSearch
} from "./profilerSelector";
import {notification} from "antd";
import AuditAction from "../audit/auditAction";
import i18n from "../../i18n";

function* getProfilerSaga(action) {
  const { id } = action.payload;
  yield put(ProfilerAction.setPersonLoading(true));
  try {
    const person = yield apiProfileService.getProfile(id);
    const personStat = yield apiProfileService.getProfileActivityStat(id);
    const personDataObject = transformPersonProfileData(
      person.data,
      personStat.data
    );
    yield put(ProfilerAction.setPerson(personDataObject));
    yield put(ProfilerAction.updateProfilerState({isNotFound: false}));
    //yield delay(2000);
    yield put(ProfilerAction.setPersonLoading(false));
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    if (
      (e?.response?.data?.message && e?.response?.data?.message?.indexOf("NOT_FOUND") === 0)
      || e?.response?.status === 404
    ) {
      yield put(ProfilerAction.updateProfilerState({isNotFound: true}));
    }
  }
}

function* getPersonalInfoSaga(action) {
  const {id, callback} = action.payload;

  try {
    const {data} = yield apiProfileService.getPersonalInformation(id);
    //console.log('getPersonalInfoSaga', data);
    const transData = transformPersonalInfoData(data);
    yield put(ProfilerAction.updateProfilerState({personalInfo: transData}));
    //yield delay(2000);
    yield callback(transData);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback({});
  }
}

function* addPersonalInfoSaga(action) {
  const {field, value, callback = null} = action.payload;

  try {
    //const {data} = yield apiProfileService.getPersonalInformation(id);
    yield put(ProfilerAction.updatePersonalInfo(field, value));
    //yield delay(2000);
    if (callback) yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    if (callback )yield callback(false);
  }
}

function* getLocationsSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getProfileLocationStat(id);
    const transformedLocations = transformLocations(data);
    yield put(ProfilerAction.updateProfilerState({locations: transformedLocations}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getFriendsSaga(action) {
  const { id, callback } = action.payload;
  try {
    //const {data} = yield apiProfileService.getFriends(id);
    const {data} = yield apiProfileService.getAdditionalFriends(id)
    //console.log('additionalData', additionalData);
    const {content, statistics} = data;
    const transData = transformFriendsData(content);
    const transStat = transformFriendsStatData(statistics);
    yield put(ProfilerAction.updateProfilerState({friends: transData, friendsStat: transStat}));

    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* fetchMoreFriendsSaga(action) {
  const { targetId, socialNetwork, socialProfileId, size = 50, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.fetchMoreFriends(
      targetId,
      {
        profileId: targetId,
        socialNetwork,
        socialProfileId,
        //numberFriends: size,
        size,
      }
    );
    const {message, status = 'SUCCESS'} = data;
    if (status === 'SUCCESS') {
      notification.success({message: i18n.t('Getting more friends process has been started successfully'), placement: 'bottomRight'});
    } else {
      notification.success({message, placement: 'bottomRight'});
    }
    yield delay(2000);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* fetchMorePostsSaga(action) {
  const { targetId, socialNetwork, socialProfileId, size = 50, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.fetchMorePosts(
      targetId,
      {
        profileId: targetId,
        socialNetwork,
        socialProfileId,
        size,
      }
    );
    const {message, status = 'SUCCESS'} = data;
    if (status === 'SUCCESS') {
      notification.success({message: i18n.t('Getting more posts process has been started successfully'), placement: 'bottomRight'});
    } else {
      notification.success({message, placement: 'bottomRight'});
    }
    yield delay(2000);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* getImagesSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getPhotos(id);
    yield put(ProfilerAction.updateProfilerState({images: data}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getWebSearchSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getWebResults(id);
    const webSearch = transformWebSearchResults(data);
    yield put(ProfilerAction.updateProfilerState({webSearch}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getPostsSaga(action) {
  const { id, filters = {}, callback } = action.payload;
  try {
    const transFilters = transformPostsFilter(filters);
    const {data} = yield apiProfileService.profileGetPosts(id, transFilters);
    const transData = transformPostsData(data);
    yield put(ProfilerAction.updateProfilerState({posts: transData}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getPreferencesSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getPreferences(id);
    const transData = transformPreferencesData(data);
    yield put(ProfilerAction.updateProfilerState({preferences: transData}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getContactInfoSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getContactInfo(id);
    const transData = transformContactInfoData(data);
    yield put(ProfilerAction.updateProfilerState({contactInfo: transData}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getDeepWebSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getDeepWeb(id);
    const transData = transformDeepWeb(data);

    //transData.hydraNewData = profilerHydraData;

    yield put(ProfilerAction.updateProfilerState({deepWeb: transData}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getSynopsisSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getSynopsis(id);
    yield put(ProfilerAction.updateProfilerState({synopsis: data}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* setSynopsisSaga(action) {
  const { id, content, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.updateSynopsis(id, {content});
    yield put(ProfilerAction.updateProfilerState({synopsis: data}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getGeneralStatSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getGeneralStat(id);
    const transData = transformGeneralStat(data);
    yield put(ProfilerAction.updateProfilerState({generalStat: transData}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getPostStatSaga(action) {
  const { id, filters, callback } = action.payload;
  const postFilters = transformPostFilters(filters);
  //console.log('filters', postFilters);
  try {
    const {data} = yield apiProfileService.getProfilePostStat(id, postFilters);
    const transData = transformPostStat(data, postFilters);
    yield put(ProfilerAction.updateProfilerState({postStat: transData}));
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getActivityStatSaga(action) {
  const { id, socialNetwork = null, callback } = action.payload;
  try {
    const {data} = socialNetwork
      ? yield apiProfileService.getProfileActivityStat(id, socialNetwork)
      : yield apiProfileService.getProfileActivityStat(id);
    const transData = transformActivityStat(data);
    yield callback(transData);
  } catch (e) {
    console.log(e);
  }
}

function* getSearchProgressSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data: searchProgress} = yield apiProfileService.profileGetSearchProgress(id);
    //const {data: imageProgress} = yield apiProfileService.getImageSearchResult(id);

    //const transData = transformSearchProgress(searchProgress, imageProgress);
    const transData = transformSearchProgress(searchProgress, []);
    yield put(ProfilerAction.updateProfilerState({searchProgress: transData}));
    yield callback(searchProgress);
  } catch (e) {
    console.log(e);
  }
}
function* getImageSearchResultsSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data: imageProgress} = yield apiProfileService.getImageSearchResult(id);

    const transData = transformImageResults(imageProgress);
    yield put(ProfilerAction.updateProfilerState({imageSearchResults: transData}));

    yield callback(transData);
  } catch (e) {
    console.log(e);
  }
}
function* getFaceRecognitionProgressSaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiJobsService.getAsyncJobStatus(id);
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getHistorySaga(action) {
  const { id, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.searchHistory(id);
    const transData = transformHistory(data);
    yield callback(transData);
  } catch (e) {
    console.log(e);
  }
}

function* getPostAnalysisSaga(action) {
  const { targetId, postId, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.profilePostAnalyze(targetId, postId);
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* getPsychoProfileSaga(action) {
  const { targetId, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.getPsychoProfile(targetId);
    const transformData = transformPsychoProfiles(data);
    console.log('ps api', transformData);
    //const data = mockPsychoProfile;
    yield delay(1000);
    yield put(ProfilerAction.updateProfilerState({psychoProfile: transformData}))
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* startPsychoProfileSaga(action) {
  const { targetId, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.startPsychoProfileAnalyze(targetId, {});
    yield callback(data);
  } catch (e) {
    console.log(e);
  }
}

function* addSourceSaga(action) {
  const {targetId, sourceData, callback} = action.payload;
  console.log('action.payload', action.payload);

  try {
    const {data} = yield apiProfileService.addSocialProfile(targetId, sourceData);
    console.log('addSourceSaga', data);
    //yield put(ProfilerAction.updatePersonalInfo(field, value));
    yield put(ProfilerAction.updateSources({
      socialNetwork: sourceData.socialNetwork,
      socialId: sourceData.profileId,
      progress: 0,
      sn: sourceData.socialNetwork,
      name: sourceData.profileId,
    }));
    yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* updateSourceSaga(action) {
  const { targetId, sourceId, socialData, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.updateSocialProfile(targetId, sourceId, socialData);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* incrementalSourceSaga(action) {
  const { targetId, sourceId, socialData, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.incrementalSearchSocialProfile(targetId, sourceId, socialData);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* deleteSourceSaga(action) {
  const { targetId, socialId, callback } = action.payload;
  console.log('deleteSourceSaga', targetId, socialId);
  try {
    const {data} = yield apiProfileService.deleteSocialProfile(targetId, socialId);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* reRunTargetSaga(action) {
  const { targetId, callback } = action.payload;
  try {
    const {data: personalData} = yield apiProfileService.getPersonalInformation(targetId);
    let validPhonesExist = false;
    personalData?.phones?.forEach(phone => {
      if (phone && typeof phone === 'string' && phone?.lastIndexOf('+') === 0) {
        validPhonesExist = true;
      }
      if (phone && typeof phone?.data === 'string' && phone?.data?.lastIndexOf('+') === 0) {
        validPhonesExist = true;
      }
    })

    if (validPhonesExist) {
      const {data} = yield apiProfileService.reRunLookup(targetId);
      notification.success({message: i18n.t('Rerun lookups has been started successfully'), placement: 'bottomRight'});
      yield delay(2000);
      yield callback(data);
    } else {
      notification.error({message: i18n.t('Rerun is not possible. The phone numbers are in invalid format'), placement: 'bottomRight'});
      yield callback(false);
    }

  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* fetchTelegramSaga(action) {
  const { targetId, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.runTelegramLookup(targetId);
    const {message, status = 'SUCCESS'} = data;
    if (status === 'SUCCESS') {
      notification.success({message: i18n.t('Getting more photos process has been started successfully'), placement: 'bottomRight'});
    } else {
      notification.success({message, placement: 'bottomRight'});
    }
    yield delay(2000);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* fetchGetContactSaga(action) {
  const { targetId, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.runGetContactLookup(targetId);

    //const fakeData = {message: "ok", status: "SUCCESS"};
    /*const fakeData = {
      "message": "Run telegram lookup for 1 000 803, is not possible, the daily limit of 3 launches has been reached",
      "status": "FAILED"
    }*/
    const {message, status} = data;
    if (status === 'SUCCESS') {
      notification.success({message: i18n.t('Getting more tags process has been started successfully'), placement: 'bottomRight'});
    } else {
      notification.success({message, placement: 'bottomRight'});
    }


    yield delay(2000);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* fetchNameSaga(action) {
  const { targetId, lookupRequestId, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.runLookup(targetId, lookupRequestId);
    notification.success({message: i18n.t('Crawlers running has been started successfully'), placement: 'bottomRight'});
    yield delay(4000);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* rerunCrawlingSaga(action) {
  const { targetId, lookupRequestId, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.reRunSearchCrawler(targetId, lookupRequestId);
    notification.success({message: i18n.t('Crawlers running has been started successfully'), placement: 'bottomRight'});
    yield delay(4000);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* addProfileItemSaga(action) {
  const { targetId, lriId, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.addProfileItemToProfile(targetId, lriId);

    yield put(ProfilerAction.getFriends(targetId, () => null));
    yield put(ProfilerAction.getPosts(targetId, {}, () => null));
    yield put(ProfilerAction.getContactInfo(targetId, () => null));
    yield put(ProfilerAction.getPreferences(targetId, () => null));

    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* deleteProfileItemSaga(action) {
  const { targetId, lriId, callback } = action.payload;
  try {
    const {data} = yield apiProfileService.removeProfileItemFromProfile(targetId, lriId);

    yield put(ProfilerAction.getFriends(targetId, () => null));
    yield put(ProfilerAction.getPosts(targetId, {}, () => null));
    yield put(ProfilerAction.getContactInfo(targetId, () => null));
    yield put(ProfilerAction.getPreferences(targetId, () => null));

    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}


function* addImageSaga(action) {
  const {targetId, formData, callback} = action.payload;
  //console.log('action.payload', action.payload);
  //console.log('formData', ...formData);

  try {
    //const {data} = yield apiProfileService.addImage(targetId, formData, {headers: {"Content-Type": "multipart/form-data"}})
    const {data} = yield axios.post(
      `${config.api_profile}/${targetId}/add-image`,
      formData,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "multipart/form-data"
        }
      }
    );
    console.log('addSourceSaga', data);
    yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* addNameSaga(action) {
  const {targetId, name, callback} = action.payload;

  try {
    const {data} = yield apiProfileService.addName(targetId, {name});
    //const {data} = yield axios.post(`${config.api_profile}/${targetId}/add-name`, formData,{headers: {"Content-Type": "multipart/form-data"}});
    console.log('addNameSaga', data);
    yield put(ProfilerAction.addPersonalInfo('names', {network: 'MANUAL', name}));
    yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* addEmailSaga(action) {
  const {targetId, email, callback} = action.payload;

  try {
    /*const {data} = */yield apiProfileService.addEmail(targetId, {email});
    //const {data} = yield axios.post(`${config.api_profile}/${targetId}/add-name`, formData,{headers: {"Content-Type": "multipart/form-data"}});
    yield put(ProfilerAction.addPersonalInfo('emails', email));
    yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* addPhoneSaga(action) {
  const {targetId, phone, callback} = action.payload;

  try {
    yield apiProfileService.addPhone(targetId, {phone});
    yield put(ProfilerAction.addPersonalInfo('phones', phone));
    yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* addPasswordSaga(action) {
  const {targetId, password, callback} = action.payload;

  try {
    yield apiProfileService.addPassword(targetId, { password });
    notification.success({message: i18n.t('Crawlers running has been started successfully'), placement: 'bottomRight'});
    yield delay(2000);
    yield put(ProfilerAction.getDeepWeb(targetId, () => {}));
    yield put(ProfilerAction.getPersonalInfo(targetId, () => {}));
    yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* addTelegramSaga(action) {
  const {targetId, telegramId, callback} = action.payload;

  try {
    yield apiProfileService.addSocialProfile(targetId, {profileId: telegramId, socialNetwork: 'TELEGRAM'});
    notification.success({message: i18n.t('Crawlers running has been started successfully'), placement: 'bottomRight'});
    yield delay(2000);
    yield put(ProfilerAction.getDeepWeb(targetId, () => {}));
    yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* deleteInfoSaga(action) {
  console.log('deleteInfoSaga', action.payload);
  const {lrID, targetId, callback} = action.payload;

  try {
    const {data} = yield apiProfileService.deleteLookupRequest(targetId, { lrID });

    console.log('removedInfo', data);
    yield callback(true);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* fetchFriendSaga(action) {
  const {targetId, friend, callback} = action.payload;

  try {
    console.log('fetchFriendSaga', targetId, friend);
    const preparedPost = prepareFetchFriendRequest(friend);
    console.log('preparedPost', ...preparedPost);

    const {data} = yield axios.post(`${config.api_profile}/create-profile`, preparedPost,{
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data"
      }
    });
    console.log('data!', data);
    const [addedProfile] = data;
    const {data: secondData} = yield apiProfileService.profileAssignProfileDataItem(addedProfile.id, friend.id);
    console.log('secondData!', secondData);
    yield callback(addedProfile.id);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* analyzeContentSaga(action) {
  const {content, callback} = action.payload;

  try {
    const preparedContent = prepareAnalyzeContent(content);
    const {data} = yield apiProfileService.analyzeEntities(preparedContent);
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* attachHydraSaga(action) {
  const {targetId, hydraItemId, callback} = action.payload;
  try {
    const {data} = yield apiProfileService.addHydraItemToProfileInfo(targetId, hydraItemId);
    yield put(ProfilerAction.getPersonalInfo(targetId, () => {}));
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* detachHydraSaga(action) {
  const {targetId, hydraItemId, callback} = action.payload;
  try {
    const {data} = yield apiProfileService.removeHydraItemToProfileInfo(targetId, hydraItemId);
    yield put(ProfilerAction.getPersonalInfo(targetId, () => {}));
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* updateSniperUrlSaga(action) {
  const {targetId, id, value, callback} = action.payload;
  try {
    const {data} = yield apiProfileService.updateSniperItem(id, value);
    yield put(ProfilerAction.getWebSearch(targetId, () => {}));
    yield put(ProfilerAction.getImageSearchResults(targetId, () => {}));
    yield callback(data);
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}


function* exportSaga(action) {
  try {
    const {type, sections} = action.payload;

    yield put(ProfilerAction.updateProfilerState({ exportLoading: true}));
    notification.success({message: i18n.t('The system is generating your export file. Do not close this tab, please.'), placement: 'bottomRight', duration: 3});

    const person = yield select(getProfilerPerson);
    const personalInfo = yield select(getProfilerPersonalInfo);
    const contactInfo = yield select(getProfilerContactInfo);
    const preferences = yield select(getProfilerPreferences);
    const images = yield select(getProfilerImages);
    const posts = yield select(getProfilerPosts);
    const searchProgress = yield select(getProfilerSearchProgress);

    //WEBP problem solution here
    //const imageSearchResults = yield select(getProfilerImageSearchResults);
    const {data: imageProgress} = yield apiProfileService.getImageSearchResult(person?.id, true, 'jpg');
    const imageSearchResults = transformImageResults(imageProgress);

    const friends = yield select(getProfilerFriends);
    const webResults = yield select(getProfilerWebSearch);
    const locations = yield select(getProfilerLocations);
    const synopsis = yield select(getProfilerSynopsis);
    const deepWeb = yield select(getProfilerDeepWeb);
    const psychoProfile = yield select(getProfilerPsychoProfile);

    const generalStat = yield select(getProfilerGeneralStat);
    const postStat = yield select(getProfilerPostStat);

    const exportData = {
      person,
      personalInfo,
      contactInfo,
      preferences,
      images,
      posts,
      searchProgress,
      imageSearchResults,
      friends,
      psychoProfile,
      webResults,
      locations,
      synopsis,
      deepWeb,
      generalStat,
      postStat,

      sections,
    };

    const exportInstance = yield ExportFactory.getExportHandler(type, exportData);
    yield exportInstance.prepareProfiler();
    yield exportInstance.export();

    yield put(ProfilerAction.updateProfilerState({ exportLoading: false}));
    yield put(AuditAction.createAudit({details: "EXPORT", actionType: "PROFILER", id: person?.id}));

  } catch (e) {
    yield put(ProfilerAction.updateProfilerState({ exportLoading: false}));
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportCaseSaga(action) {
  const {type, caseId, callback} = action.payload;

  try {
    const {data: {content: profilerTargets = []}} = yield apiCaseService.getCaseProfiles(caseId, 0, 1000);

    const callEffects = profilerTargets.map(({id}) => {
      return call(function* () {
        const {data: personalInfo} = yield apiProfileService.getPersonalInformation(id);
        const {data: deepWebInfo} = yield apiProfileService.getDeepWeb(id);
        return {...personalInfo, deepWebInfo};
      });
    })

    const profilePersonalData = yield all(callEffects);
    //console.log('profilePersonalData', profilePersonalData);

    const exportInstance = yield ExportFactory.getExportHandler(type, profilePersonalData);
    yield exportInstance.prepareProfilerCase(caseId);
    yield exportInstance.export();

    callback(true)

  } catch (e) {
    callback(false)
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* exportAISaga(action) {
  const {type, aiData, callback} = action.payload;

  try {
    const exportInstance = yield ExportFactory.getExportHandler(type, aiData);
    yield exportInstance.prepareProfilerAI();
    yield exportInstance.export();

    callback(true)

  } catch (e) {
    callback(false)
    yield put(SettingsAction.handleAPIError(e));
  }
}

function* startSilentRefreshSaga(action) {
  const {targetId, callback} = action.payload;

  try {
    yield put(ProfilerAction.updateProfilerState({silentLoading: true}))

    //silent update
    //yield put(ProfilerAction.getPersonalInfo(targetId, () => {}));
    //yield put(ProfilerAction.getFriends(targetId, () => {}));

    const defaultPayload = {payload: {id: targetId, callback: () => {}}};
    yield call(getProfilerSaga, {payload: {id: targetId}});
    yield call(getPersonalInfoSaga, defaultPayload);
    yield call(getSearchProgressSaga, defaultPayload);
    yield call(getFriendsSaga, defaultPayload);
    yield call(getImagesSaga, defaultPayload);
    yield call(getWebSearchSaga, defaultPayload);
    yield call(getPostsSaga, defaultPayload);
    yield call(getPreferencesSaga, defaultPayload);
    yield call(getContactInfoSaga, defaultPayload);
    yield call(getGeneralStatSaga, defaultPayload);
    //yield call(getFriendsSaga, payload);


    //yield delay(2000);


    yield put(ProfilerAction.updateProfilerState({silentLoading: false}))
    callback(true)

  } catch (e) {
    callback(false)
    yield put(SettingsAction.handleAPIError(e));
  }
}


function* analyzeTargetSaga(action) {
  const { targetId, target, reRun = false, callback } = action.payload;
  try {

    /*const promptTemplate = `Create a comprehensive and analytical profile of the individual described in the provided JSON file.
      Cover all relevant aspects, including personal characteristics, behavioral tendencies, communication style, interests, 
      and any identifiable patterns in activities. 
      Highlight the individual’s strengths and weaknesses, along with any unique qualities or notable patterns. 
      Avoid referencing information about other people, and use emojis to enhance readability. 
      Ensure the profile is structured, well-organized, and provides an in-depth view of the individual’s personal and social characteristics. 
      Return result as proper HTML div container. Do not add any html prefixes at the start and end result. Do not return any images.
    JSON file: `;

    const postData = {
      promt: `${promptTemplate} ${JSON.stringify(target)}`,
      openAIModel: 'gpt-4o-mini',
    }
    const {data} = yield apiAudioService.promtAnalyze(postData);*/
    //console.log('analyze saga length', `${JSON.stringify(target)}`.length)
    const postData = {
      json: `${JSON.stringify(target)}`,
      isRerun: !!reRun,
    }
    const {data} = yield apiProfileService.profileOpenAIAnalyse(targetId, postData);

    console.log('RESULT data', data);

    yield callback(data);

  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* analyzeContactInfoSaga(action) {
  const { targetId, contactInfo, callback = () => {} } = action.payload;
  try {
    /*const promptTemplate = `Create a comprehensive and analytical profile of the individual described in the provided contact info JSON file.
      Cover all relevant aspects, including personal characteristics, behavioral tendencies, communication style, interests,
      and any identifiable patterns in activities.
      Highlight the individual’s strengths and weaknesses, along with any unique qualities or notable patterns.
      Avoid referencing information about other people, and use emojis to enhance readability.
      Ensure the profile is structured, well-organized, and provides an in-depth view of the individual’s personal and social characteristics.
      Return result as proper HTML div container. Do not add any html prefixes at the start and end result. Do not return any images.
    JSON file: `;*/
    const promptTemplate = `Create a detailed and analytical report about the individual described in the provided JSON file, focusing on the "Contact Info" section extracted from social network profiles (Facebook, LinkedIn, VK, OK, and Instagram). Analyze and summarize all available information, including: - :dart: Professional skills and expertise - :birthday: Age and gender - :earth_africa: Locations and geographic patterns - :drawing_pin: Personal and professional interests - :bar_chart: Any identifiable trends or patterns across the different platforms Provide insights into the person’s strengths, unique qualities, and potential areas of interest or influence. Use appropriate emojis throughout the report to emphasize key points, enhance readability, and make the content visually engaging. Structure the report with clear headings and organized sections. Enhance readability with bullet points, concise summaries, and visually appealing formatting. Return the result as a proper HTML div container. Do not add any HTML prefixes at the start or end of the result. Do not return any images. JSON file `;

    const postData = {
      promt: `${promptTemplate} ${JSON.stringify(contactInfo)}`,
      openAIModel: 'gpt-4o-mini',
    }
    const {data} = yield apiAudioService.promtAnalyze(postData);

    yield callback(data);

  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}

function* getAnalyzeTargetDataSaga(action) {
  const { targetId, callback } = action.payload;
  try {

    const person = yield select(getProfilerPerson);
    const personalInfo = yield select(getProfilerPersonalInfo);
    const contactInfo = yield select(getProfilerContactInfo);
    const preferences = yield select(getProfilerPreferences);
    const images = yield select(getProfilerImages);
    const posts = yield select(getProfilerPosts);
    const searchProgress = yield select(getProfilerSearchProgress);

    const friends = yield select(getProfilerFriends);
    const webResults = yield select(getProfilerWebSearch);
    const locations = yield select(getProfilerLocations);
    const synopsis = yield select(getProfilerSynopsis);
    const deepWeb = yield select(getProfilerDeepWeb);
    const psychoProfile = yield select(getProfilerPsychoProfile);

    const toAnalyzeData = {
      person,
      personalInfo,
      contactInfo,
      preferences,
      images,
      posts,
      searchProgress,
      friends,
      psychoProfile,
      webResults,
      locations,
      synopsis,
      deepWeb,
    };
    console.log('toAnalyzeData', toAnalyzeData);

    yield callback(toAnalyzeData);
    /*const {message, status = 'SUCCESS'} = data;
    if (status === 'SUCCESS') {
      notification.success({message: i18n.t('Getting more friends process has been started successfully'), placement: 'bottomRight'});
    } else {
      notification.success({message, placement: 'bottomRight'});
    }
    yield delay(2000);
    yield callback(data);*/
  } catch (e) {
    yield put(SettingsAction.handleAPIError(e));
    yield callback(false);
  }
}


export function* watchProfiler() {
  yield takeEvery(ProfilerAction.GET_PERSON, getProfilerSaga);
  yield takeEvery(ProfilerAction.GET_PERSONAL_INFO, getPersonalInfoSaga);
  yield takeEvery(ProfilerAction.ADD_PERSONAL_INFO, addPersonalInfoSaga);
  yield takeEvery(ProfilerAction.GET_LOCATIONS, getLocationsSaga);
  yield takeEvery(ProfilerAction.GET_FRIENDS, getFriendsSaga);
  yield takeEvery(ProfilerAction.FETCH_MORE_FRIENDS, fetchMoreFriendsSaga);
  yield takeEvery(ProfilerAction.FETCH_MORE_POSTS, fetchMorePostsSaga);
  yield takeEvery(ProfilerAction.GET_IMAGES, getImagesSaga);
  yield takeEvery(ProfilerAction.GET_WEB_SEARCH, getWebSearchSaga);
  yield takeEvery(ProfilerAction.GET_POSTS, getPostsSaga);
  yield takeEvery(ProfilerAction.GET_PREFERENCES, getPreferencesSaga);
  yield takeEvery(ProfilerAction.GET_CONTACT_INFO, getContactInfoSaga);
  yield takeEvery(ProfilerAction.GET_DEEP_WEB, getDeepWebSaga);
  yield takeEvery(ProfilerAction.GET_SYNOPSIS, getSynopsisSaga);
  yield takeEvery(ProfilerAction.SET_SYNOPSIS, setSynopsisSaga);
  yield takeEvery(ProfilerAction.GET_GENERAL_STAT, getGeneralStatSaga);
  yield takeEvery(ProfilerAction.GET_POST_STAT, getPostStatSaga);
  yield takeEvery(ProfilerAction.GET_ACTIVITY_STAT, getActivityStatSaga);
  yield takeEvery(ProfilerAction.GET_SEARCH_PROGRESS, getSearchProgressSaga);
  yield takeEvery(ProfilerAction.GET_IMAGE_SEARCH_RESULTS, getImageSearchResultsSaga);
  yield takeEvery(ProfilerAction.GET_FACE_RECOGNITION_PROGRESS, getFaceRecognitionProgressSaga);
  yield takeEvery(ProfilerAction.GET_HISTORY, getHistorySaga);
  yield takeEvery(ProfilerAction.GET_POST_ANALYSIS, getPostAnalysisSaga);
  yield takeEvery(ProfilerAction.GET_PSYCHO_PROFILE, getPsychoProfileSaga);
  yield takeEvery(ProfilerAction.START_PSYCHO_PROFILE, startPsychoProfileSaga);
  yield takeEvery(ProfilerAction.ADD_SOURCE, addSourceSaga);
  yield takeEvery(ProfilerAction.UPDATE_SOURCE, updateSourceSaga);
  yield takeEvery(ProfilerAction.INCREMENTAL_SOURCE, incrementalSourceSaga);
  yield takeEvery(ProfilerAction.DELETE_SOURCE, deleteSourceSaga);
  yield takeEvery(ProfilerAction.RERUN_TARGET, reRunTargetSaga);
  yield takeEvery(ProfilerAction.FETCH_TELEGRAM, fetchTelegramSaga);
  yield takeEvery(ProfilerAction.FETCH_GETCONTACT, fetchGetContactSaga);
  yield takeEvery(ProfilerAction.FETCH_NAME, fetchNameSaga);
  yield takeEvery(ProfilerAction.RERUN_CRAWLING, rerunCrawlingSaga);
  yield takeEvery(ProfilerAction.ADD_PROFILE_ITEM, addProfileItemSaga);
  yield takeEvery(ProfilerAction.DELETE_PROFILE_ITEM, deleteProfileItemSaga);
  yield takeEvery(ProfilerAction.ADD_IMAGE, addImageSaga);
  yield takeEvery(ProfilerAction.ADD_NAME, addNameSaga);
  yield takeEvery(ProfilerAction.ADD_EMAIL, addEmailSaga);
  yield takeEvery(ProfilerAction.ADD_PHONE, addPhoneSaga);
  yield takeEvery(ProfilerAction.ADD_PASSWORD, addPasswordSaga);
  yield takeEvery(ProfilerAction.ADD_TELEGRAM, addTelegramSaga);
  yield takeEvery(ProfilerAction.DELETE_PROFILE_INFO, deleteInfoSaga);
  yield takeEvery(ProfilerAction.FETCH_FRIEND, fetchFriendSaga);
  yield takeEvery(ProfilerAction.ANALYZE_CONTENT, analyzeContentSaga);
  yield takeEvery(ProfilerAction.ATTACH_HYDRA, attachHydraSaga);
  yield takeEvery(ProfilerAction.DETACH_HYDRA, detachHydraSaga);
  yield takeEvery(ProfilerAction.UPDATE_SNIPER_URL, updateSniperUrlSaga);
  yield takeEvery(ProfilerAction.EXPORT, exportSaga);
  yield takeEvery(ProfilerAction.EXPORT_CASE, exportCaseSaga);
  yield takeEvery(ProfilerAction.EXPORT_AI, exportAISaga);
  yield takeEvery(ProfilerAction.START_SILENT_REFRESH, startSilentRefreshSaga);
  yield takeEvery(ProfilerAction.ANALYZE_TARGET, analyzeTargetSaga);
  yield takeEvery(ProfilerAction.GET_ANALYZE_TARGET_DATA, getAnalyzeTargetDataSaga);
  yield takeEvery(ProfilerAction.ANALYZE_CONTACT_INFO, analyzeContactInfoSaga);
}
