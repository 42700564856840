import React from 'react';
import {Image, Text, View} from "@react-pdf/renderer";
import {imageByIndex} from "../../../../../helpers/imageByIndex";
import {CountryIcon, SocialIcon} from "../../Icons";
import {withTranslation} from "react-i18next";

const PersonalInfo = ({t, data}) => {
  const { person, personalInfo } = data;
  const {id, photo, name, phone} = person;
  const {countryCode, names = []} = personalInfo;


  return (
    <View>
      {/*<View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', backgroundColor: '#f8f8f8', padding: '10px'}}>
        <View>
          {
            photo && <Image style={{width: '100px', height: '100px', marginRight: '20px'}} src={imageByIndex(photo)}/>
          }
        </View>

        <View>
          <Text style={{fontSize: '18px', fontFamily: 'pdfFont',}}>{name}</Text>
          {
            !!countryCode && <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginTop: '15px'}}>
              <CountryIcon country={countryCode} />
              <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{countryCode}</Text>
            </View>
          }
          <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{phone || id}</Text>
          </View>
        </View>

      </View>*/}

      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}}>
        <View style={{width: '100px', marginRight: '20px', flexShrink: 0}}>
          <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{t("Names")}:</Text>
        </View>

        <View>
          {
            names.map(({network, name}, idx) => <View key={idx} style={{marginBottom: '10px'}}>
              <Text style={{fontSize: '10px', fontWeight: '700', marginBottom: '5px', fontFamily: 'pdfFont',}}>{name}</Text>
              {
                network && <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px'}}>
                  <SocialIcon network={network} />
                  <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{network}</Text>
                </View>
              }
            </View>)
          }
        </View>
      </View>

    </View>
  );
};

export default withTranslation()(PersonalInfo);