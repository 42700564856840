import React from "react";
import ExportPdfProfiler from "./ExportPdfProfiler";
import ExportPdfWebint from "./ExportPdfWebint";
import ExportPdfGroupsActivity from "./ExportPdfGroupsActivity";

import {pdf} from '@react-pdf/renderer';
import moment from "moment";
import ExportPdfWebintUserStat from "./ExportPdfWebintUserStat";
import ExportPdfAvatarAudit from "./ExportPdfAvatarAudit";
import ExportPdfAvatarBlocked from "./ExportPdfAvatarBlocked";
import ExportPdfProfilerAI from "./ExportPdfProfilerAI";

class ExportPdf {
  constructor(data) {
    this.data = [];
    this.rawData = Array.isArray(data) ? [...data] : {...data};
    this.fileName = "PROFILER_" + moment().format("YYYY_MM_DD_HH_mm_ss");
  }
  prepareProfiler() {
    console.log('this.rawData', this.rawData)
    const {person} = this.rawData;
    this.fileName = "PROFILER_" + person?.id + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data = <ExportPdfProfiler data={this.rawData} />;
    return this;
  }
  prepareWebint() {
    console.log('this.rawData', this.rawData)
    const {task} = this.rawData;
    this.fileName = "WEBINT_" + task?.id + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data = <ExportPdfWebint data={this.rawData} />;
    return this;
  }
  prepareGroupsActivity() {
    console.log('this.rawData', this.rawData)
    this.fileName = "GROUPS_ACTIVITY_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data = <ExportPdfGroupsActivity data={this.rawData} />;
    return this;
  }
  prepareWebintUserStat() {
    console.log('this.rawData', this.rawData)
    this.fileName = "WEBINT_USER_STAT_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data = <ExportPdfWebintUserStat data={this.rawData} />;
    return this;
  }
  prepareAvatarAudit() {
    this.fileName = "AVATAR_LOG_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data = <ExportPdfAvatarAudit data={this.rawData} />;
    return this;
  }
  prepareAvatarBlocked() {
    this.fileName = "AVATAR_BLOCKED_" + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data = <ExportPdfAvatarBlocked data={this.rawData} />;
    return this;
  }
  prepareProfilerAI() {
    const {targetId} = this.rawData;
    this.fileName = "PROFILER_AI_" + targetId + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data = <ExportPdfProfilerAI data={this.rawData} />;
    return this;
  }

  prepareWebintAI() {
    const {targetId} = this.rawData;
    this.fileName = "WEBINT_AI_" + targetId + '_' + moment().format("YYYY_MM_DD_HH_mm_ss");
    this.data = <ExportPdfProfilerAI data={this.rawData} />;
    return this;
  }

  async export() {
    await this.downloadBlob(pdf(this.data).toBlob());
    return this;
  }

  //other methods
  downloadBlob(promise) {
    return new Promise(resolve => {
      promise.then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.fileName + '.pdf';
        document.body.append(link);
        link.click();
        link.remove();
        setTimeout(() => {
          URL.revokeObjectURL(link.href)
          resolve('resolved');
        }, 3000);
      })
    });
  }
}

export default ExportPdf