import React from 'react';
import {Text, View} from "@react-pdf/renderer";
import {withTranslation} from "react-i18next";

const Statistics = ({t, data}) => {
  const { generalStat, postStat } = data;
  const { post, sentiment, socialNetwork} = generalStat;
  console.log('statistics', data);

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Statistics")}</Text>

      <View style={{padding: '20px', border: '1px solid #ececec'}}>
        {
          post?.length > 0 && <>
            <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Posts statistics")}:</Text>
            {
              post?.map((item, index) => <View key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <View style={{width: '25%'}}><Text style={{fontSize: '14px', fontWeight: 'bold', fontFamily: 'pdfFont',}}>{item?.type}</Text></View>
                <View style={{}}><Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{item?.value}</Text></View>
              </View>)
            }
            <View style={{paddingBottom: '30px'}}></View>
          </>
        }
        {
          socialNetwork?.length > 0 && <>
            <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Activity statistics")}:</Text>
            {
              socialNetwork?.map((item, index) => <View key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <View style={{width: '25%'}}><Text style={{fontSize: '14px', fontWeight: 'bold', fontFamily: 'pdfFont'}}>{item?.type}</Text></View>
                <View style={{}}><Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{item?.value}</Text></View>
              </View>)
            }
            <View style={{paddingBottom: '30px'}}></View>
          </>
        }
        {
          sentiment?.length > 0 && <>
            <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Sentiment statistics")}:</Text>
            {
              sentiment?.map((item, index) => <View key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <View style={{width: '25%'}}><Text style={{fontSize: '14px', fontWeight: 'bold', fontFamily: 'pdfFont'}}>{item?.type}</Text></View>
                <View style={{}}><Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{item?.value}</Text></View>
              </View>)
            }
            <View style={{paddingBottom: '30px'}}></View>
          </>
        }
        {
          postStat?.length > 0 && <>
            <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Posts activity")}:</Text>
            {
              postStat?.filter(item => item?.value)?.map((item, index) => <View key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <View style={{width: '25%'}}><Text style={{fontSize: '14px', fontWeight: 'bold', fontFamily: 'pdfFont'}}>{item?.type}</Text></View>
                <View style={{width: '25%'}}><Text style={{fontSize: '14px', fontWeight: 'bold', fontFamily: 'pdfFont'}}>{item?.date}</Text></View>
                <View style={{}}><Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{item?.value}</Text></View>
              </View>)
            }
            <View style={{paddingBottom: '30px'}}></View>
          </>
        }
      </View>
    </View>
  );
};

export default withTranslation()(Statistics);