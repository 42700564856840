import React from 'react';
import {Page, Text, View, Document, Font} from '@react-pdf/renderer';
import {withTranslation} from "react-i18next";
import font from "../../../../assets/fonts/arial-unicode-ms.ttf";
import {EXT_TITLE, VERSION} from "../../../../config/consts";
import {styles} from "./styles";
import MainPage from "./MainPage";
import PersonalInfo from "./PersonalInfo";
import Phones from "./Phones";
import MainInfo from "./MainInfo";
import Profiles from "./Profiles";
import ContactInfo from "./ContactInfo";
import Preferences from "./Preferences";
import Images from "./Images";
import Summary from "./Statistics";
import Posts from "./Posts";
import SearchResults from "./SearchResults";
import Friends from "./Friends";
import WebResults from "./WebResults";
import Locations from "./Locations";
import {SupportService} from "../../../support";
import DeepWeb from "./DeepWeb";
import config from "../../../../config/config";
import ImageSearchResults from "./ImageSearchResults";
import PsychoProfiles from "./PsychoProfiles";
import Person from "./Person";
import Statistics from "./Statistics";

Font.register({
  family: 'pdfFont',
  src: font
});
Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});




const ExportPdfProfiler = ({t, data}) => {
  const { synopsis, sections = [] } = data;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>{t("Created by")} {config.appTitle || EXT_TITLE} {VERSION}</Text>
        <View style={styles.headerPadding} fixed/>


        <MainPage data={data} />
        <Text break/>

        {
          sections.includes('person') && <Person data={data} />
        }

        {
          sections.includes('personal') && <>
            <PersonalInfo data={data} />
            <Phones data={data} />
            <MainInfo data={data} />
            <Text break/>

            <Profiles data={data} />
            <Text break/>
          </>
        }

        {
          sections.includes('locations') && <>
            <Locations data={data} />
            <Text break />
          </>
        }

        {
          sections.includes('search_results') && <>
            <SearchResults data={data} />
            <Text break />
          </>
        }

        {
          sections.includes('image_search_results') && <>
            <ImageSearchResults data={data} />
            <Text break />
          </>
        }

        {
          sections.includes('statistics') && <>
            <Statistics data={data} />
            <Text break/>
          </>
        }

        {
          sections.includes('friends') && <>
            <Friends data={data} />
            <Text break />
          </>
        }

        {
          sections.includes('photos') && <>
            <Images data={data} />
            <Text break/>
          </>
        }

        {
          sections.includes('posts') && <>
            <Posts data={data} />
            <Text break />
          </>
        }

        {
          sections.includes('web_results') && <>
            <WebResults data={data} />
            <Text break />
          </>
        }

        {
          sections.includes('preferences') && <>
            <Preferences data={data} />
            <Text break/>
          </>
        }

        {
          sections.includes('contact') && <>
            <ContactInfo data={data} />
            <Text break/>
          </>
        }

        {/*{
          sections.includes('summary') && <>
            <Summary data={data} />
            <Text break />
          </>
        }*/}

        {
          sections.includes('synopsis') && <>
            <Text style={{marginBottom: '20px'}}>{t("Synopsis")}</Text>
            <Text style={{fontSize: '12px', fontFamily: 'pdfFont',}}>{SupportService.stripHtmlTags(synopsis)}</Text>
          </>
        }

        {
          sections.includes('deep_web') && <>
            <DeepWeb data={data} />
            <Text break />
          </>
        }

        {
          sections.includes('psycho') && <>
            {
              config.profilerPsychoProfile && <PsychoProfiles data={data} />
            }
            {
              config.profilerPsychoProfile && <Text break />
            }
          </>
        }



        <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  )
};


export default withTranslation()(ExportPdfProfiler);