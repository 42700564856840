import React from "react";
import styled from "styled-components";
import {Tooltip} from "antd";
import {SupportService} from "../../../../services/support";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  margin: 0 5px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

const SocialIcon = (
  {
    type,
    text,
    title = null,
    size = '',
    style,
    styleText,
    styleImg,
    tooltipPlace = "top",
  }) => {
    const images = require.context('../../../../assets/img/socials', true);
    const inputTitle = (title || type)?.toUpperCase();
    const socTitle = SupportService.specialSocialNetworksNames?.[inputTitle] || (title || type);

    try{
      const img = images('./' + (size ? `${size}/` : '') + type.toLowerCase() + '.png');
      return <Container style={style}>
        <Tooltip
          placement={tooltipPlace || "top"}
          title={socTitle}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
        >
          <img
            alt={type}
            //title={title ? title : type}
            src={img.default}
            style={{width: size ? `${size}px` : '16px', ...styleImg}}
          />
        </Tooltip>
        {
          !!text && <TextContainer className={'social-icon-text'} style={styleText}>{text}</TextContainer>
        }
      </Container>;
    } catch (e) {
      return <Container style={style}>
        {
          !!text && <TextContainer className={'social-icon-text'} style={styleText}>{text}</TextContainer>
        }
      </Container>;
    }
};

export default SocialIcon;