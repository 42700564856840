import moment from "moment";
import config from "../../config/config";

export const transformInvestigations = (data, user) => {
  const invest = [];
  Object.keys(data).forEach((key) => {
    data[key].invList.forEach((el) => invest.push(el));
  });
  return {
    array: invest.map((el) => {
      return {
        ...el,
        key: el.invNumber,
        title: el.invName,
        description: el.createdBy,
      };
    }),
    targetKeys: data[user.userName].invList.map((el) => el.invNumber),
  };
};

export const transformInvestToEdit = (dataObj) => {
  return dataObj.array
    .filter((el) => dataObj.targetKeys.includes(el.key))
    .map((el) => `${el.invNumber}`);
};

export const getQuotaDate = (payloadDate, initialDate) => {
  if (payloadDate) {
    return moment(payloadDate).format("YYYY-MM-DD");
  }
  if (initialDate) {
    return moment(initialDate).format("YYYY-MM-DD");
  } else {
    return null;
  }
};


export const transformUserPermissions = (userInfo = {}, authInfo = {}) => {
  const permissions = {};
  const {profilerIsActive, webintIsActive} = userInfo;
  const {roles} = authInfo;
  const isAdmin = roles.includes('ADMIN');
  const isUser = roles.includes('USER');
  const webintTypes = config.webintTaskTypesAvailable ? config.webintTaskTypesAvailable.split(',') : [];

  //modules
  permissions['module.profiler'] = profilerIsActive;
  permissions['module.webint'] = webintIsActive;
  permissions['module.avatars'] = isAdmin || config.moduleAvatars;
  permissions['module.alerts'] = isAdmin || config.moduleAlerts;
  permissions['module.top_posts'] = isAdmin || config.moduleTopPosts;

  //header
  permissions['header.new_tab'] = config.headerNewTab;
  permissions['header.languages'] = isAdmin || config.headerLanguages;
  permissions['header.logout'] = isAdmin || config.headerLogout;

  permissions['role.admin'] = isAdmin;
  permissions['role.user'] = isUser;

  //profiler
  permissions['profiler.use_avatars'] = config.profilerUseAvatars;
  permissions['profiler.search_single_option'] = config.profilerSearchSingleOption;
  permissions['profiler.add_phone'] = config.profilerAddPhone;
  permissions['profiler.add_source_from_networks'] = config.profilerAddSourceFromNetworks;
  permissions['profiler.use_hydra'] = config.profilerUseHydra;
  permissions['profiler.update_sources'] = isAdmin || config.profilerUpdateSources;
  permissions['profiler.fetch_more_friends'] = config.profilerFetchMoreFriends;
  permissions['profiler.fetch_more_posts'] = config.profilerFetchMorePosts;
  permissions['profiler.post_ai_analysis'] = config.profilerPostAIAnalysis;
  permissions['profiler.show_verified_sign'] = config.profilerShowVerifiedSign;
  permissions['profiler.auto_refresh'] = config.profilerAutoRefresh;
  permissions['profiler.ai_contact_info'] = config.profilerAiContactInfo;

  permissions['avatars.use_default'] = isAdmin && config.avatarsUseDefault;
  permissions['avatars.ai_posts_generator'] = /*isAdmin && */config.avatarsAiPostsGeneration;


  permissions['webint.telegram_get_more_photos'] = config.webintTelegramMorePhotos;
  permissions['webint.getcontact_get_more_tags'] = config.webintGetcontactMoreTags;
  permissions['webint.post_ai_analysis'] = config.webintPostAIAnalysis;
  ['groups', 'keywords', 'posts', 'keywords_groups'].forEach(taskType => {
    permissions[`webint.types.${taskType}`] = webintTypes.includes(taskType)
  })


  //browse
  permissions['browse.delete_items'] = isAdmin || config.browseDeleteItems;
  permissions['browse.rename_items'] = isAdmin || config.browseRenameItems;
  permissions['browse.context_menu'] = isAdmin || (config.browseRenameItems || config.browseDeleteItems);

  //investigation
  permissions['investigation.show_creator'] = isAdmin || config.investigationShowCreator;

  //users
  permissions['user.daily_quota'] = config.userDailyQuota;
  permissions['user.rename'] = config.userRename;


  ///console.log('!!!!!!transformUserPermissions', permissions);


  return permissions;
};