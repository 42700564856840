import ActionUtility from "../ActionUtility";

export default class WebintAction {
  static UPDATE_WEBINT_STATE = "WebintAction.UPDATE_WEBINT_STATE";

  static GET_TASK = "WebintAction.GET_TASK";
  static UPDATE_TASK = "WebintAction.UPDATE_TASK";

  static GET_GROUPS = "WebintAction.GET_GROUPS";
  static GET_TARGETS = "WebintAction.GET_TARGETS";
  static GET_POSTS = "WebintAction.GET_POSTS";
  static GET_NEWS_AND_UPDATES = "WebintAction.GET_NEWS_AND_UPDATES";
  static GET_SEARCH_PROGRESS = "WebintAction.GET_SEARCH_PROGRESS";
  static GET_IMAGES = "WebintAction.GET_IMAGES";
  static GET_VIDEOS = "WebintAction.GET_VIDEOS";
  static GET_TOP_POSTS = "WebintAction.GET_TOP_POSTS";
  static GET_TRENDS = "WebintAction.GET_TRENDS";
  static GET_POST_STAT = "WebintAction.GET_POST_STAT";

  static FETCH_TARGET = "WebintAction.FETCH_TARGET";
  static FETCH_GROUP = "WebintAction.FETCH_GROUP";
  static FETCH_TASK = "WebintAction.FETCH_TASK";
  static GET_POST_AVATAR = "WebintAction.GET_POST_AVATAR";
  static GET_POST_HISTORY = "WebintAction.GET_POST_HISTORY";
  static GET_POST_100 = "WebintAction.GET_POST_100";
  static GET_GROUPS_ACTIVITY = "WebintAction.GET_GROUPS_ACTIVITY";
  static GET_INVESTIGATION_GROUPS = "WebintAction.GET_INVESTIGATION_GROUPS";
  static GET_WEBINT_STAT = "WebintAction.GET_WEBINT_STAT";
  static GET_WEBINT_USER_STAT = "WebintAction.GET_WEBINT_USER_STAT";
  static GET_POST_ANALYSIS = "WebintAction.GET_POST_ANALYSIS";
  static ANALYZE_TARGET = "WebintAction.ANALYZE_TARGET";


  static EXPORT = "WebintAction.EXPORT";
  static EXPORT_POSTS_100 = "WebintAction.EXPORT_POSTS_100";
  static EXPORT_GROUPS_ACTIVITY = "WebintAction.EXPORT_GROUPS_ACTIVITY";
  static EXPORT_WEBINT_USER_STAT = "WebintAction.EXPORT_WEBINT_USER_STAT";
  static EXPORT_AI = "WebintAction.EXPORT_AI";


  static updateWebintState(action) {
    return ActionUtility.createAction(WebintAction.UPDATE_WEBINT_STATE, action);
  }

  /* Groups */
  static getTask(id, callback) {
    return ActionUtility.createAction(WebintAction.GET_TASK, {id, callback,});
  }

  /* Update Task */
  static updateTask(id, formData, callback) {
    return ActionUtility.createAction(WebintAction.UPDATE_TASK, {id, formData, callback,});
  }

  /* Groups */
  static getGroups(id, filterData = {}, callback) {
    return ActionUtility.createAction(WebintAction.GET_GROUPS, {id, filterData, callback,});
  }

  /* Targets */
  static getTargets(id, filterData = {}, callback) {
    return ActionUtility.createAction(WebintAction.GET_TARGETS, {id, filterData, callback,});
  }

  /* Posts */
  static getPosts(id, filters, callback) {
    return ActionUtility.createAction(WebintAction.GET_POSTS, {id, filters, callback,});
  }

  /* News and Updates */
  static getNewsAndUpdates(id, callback) {
    return ActionUtility.createAction(WebintAction.GET_NEWS_AND_UPDATES, {id, callback,});
  }

  /* Search Progress */
  static getSearchProgress(id, callback) {
    return ActionUtility.createAction(WebintAction.GET_SEARCH_PROGRESS, {id, callback,});
  }

  /* Images */
  static getImages(id, filterData = {}, callback) {
    return ActionUtility.createAction(WebintAction.GET_IMAGES, {id, filterData, callback,});
  }

  /* Videos */
  static getVideos(id, filterData = {}, callback) {
    return ActionUtility.createAction(WebintAction.GET_VIDEOS, {id, filterData, callback,});
  }

  /* TOP Posts */
  static getTopPosts(id, callback) {
    return ActionUtility.createAction(WebintAction.GET_TOP_POSTS, {id, callback,});
  }

  /* Trends */
  static getTrends(keyword, callback) {
    return ActionUtility.createAction(WebintAction.GET_TRENDS, {keyword, callback,});
  }

  /*Posts stat*/
  static getPostStat(id, filters, callback) {
    return ActionUtility.createAction(WebintAction.GET_POST_STAT, {id, filters, callback,});
  }


  /* Fetch target */
  static fetchTarget(id, target, callback) {
    return ActionUtility.createAction(WebintAction.FETCH_TARGET, {id, target, callback,});
  }

  /* Fetch group */
  static fetchGroup(id, callback) {
    return ActionUtility.createAction(WebintAction.FETCH_GROUP, {id, callback});
  }

  /* Fetch task */
  static fetchTask(id, callback) {
    return ActionUtility.createAction(WebintAction.FETCH_TASK, {id, callback});
  }

  /* Get post avatar */
  static getPostAvatar(postId, callback) {
    return ActionUtility.createAction(WebintAction.GET_POST_AVATAR, {postId, callback});
  }

  /* Get post history */
  static getPostHistory(id, postId, callback) {
    return ActionUtility.createAction(WebintAction.GET_POST_HISTORY, {id, postId, callback});
  }

  /* Get top 100 posts */
  static getPost100(filters, callback) {
    return ActionUtility.createAction(WebintAction.GET_POST_100, {filters, callback});
  }

  /* Get Groups activity */
  static getGroupsActivity(invId, filters, callback) {
    return ActionUtility.createAction(WebintAction.GET_GROUPS_ACTIVITY, {invId, filters, callback});
  }

  /* Get Investigation Groups list */
  static getInvestigationGroups(invId, callback) {
    return ActionUtility.createAction(WebintAction.GET_INVESTIGATION_GROUPS, {invId, callback});
  }

  /* Export */
  static export(type, sections) {
    return ActionUtility.createAction(WebintAction.EXPORT, {type, sections});
  }

  /* Export top 100 */
  static exportPosts100(type, selectedPosts) {
    return ActionUtility.createAction(WebintAction.EXPORT_POSTS_100, {type, selectedPosts});
  }

  /* Export group activity */
  static exportGroupsActivity(type, activityData) {
    return ActionUtility.createAction(WebintAction.EXPORT_GROUPS_ACTIVITY, {type, activityData});
  }

  /* Export group activity */
  static exportWebintUserStatActivity(type, statData, filters) {
    return ActionUtility.createAction(WebintAction.EXPORT_WEBINT_USER_STAT, {type, statData, filters});
  }

  /* Get Webint statistics */
  static getWebintStat(callback) {
    return ActionUtility.createAction(WebintAction.GET_WEBINT_STAT, callback);
  }
  /* Get Webint user statistics */
  static getWebintUserStat(filters, callback) {
    return ActionUtility.createAction(WebintAction.GET_WEBINT_USER_STAT, {filters, callback});
  }

  /*Get AI post analysis*/
  static getPostAnalysis(targetId, postId, callback) {
    return ActionUtility.createAction(WebintAction.GET_POST_ANALYSIS, {targetId, postId, callback,});
  }

  static analyzeTarget(targetId, target, reRun = false, callback) {
    return ActionUtility.createAction(WebintAction.ANALYZE_TARGET, {targetId, target, reRun, callback,});
  }

  static exportAI(type, aiData, callback) {
    return ActionUtility.createAction(WebintAction.EXPORT_AI, {type, aiData, callback,});
  }

}
