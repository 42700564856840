import React from 'react';
import {Image, Link, Text, View} from "@react-pdf/renderer";
import {imageByIndex} from "../../../../../helpers/imageByIndex";
import {CountryIcon, SocialIcon} from "../../Icons";
import {withTranslation} from "react-i18next";
import {SupportService} from "../../../../support";

const Person = ({t, data}) => {
  const { person, personalInfo } = data;
  const {id, photo, name, phone, commonStatistics} = person;
  const {countryCode, names = []} = personalInfo;
  console.log('person pdf', data);


  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Person")}</Text>
      <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', backgroundColor: '#f8f8f8', padding: '10px'}}>
        <View>
          {
            photo && <Image style={{width: '100px', height: '100px', marginRight: '20px'}} src={imageByIndex(photo)}/>
          }
        </View>

        <View>
          <Text style={{fontSize: '18px', fontFamily: 'pdfFont',}}>{name}</Text>
          {
            !!countryCode && <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '5px', marginTop: '15px'}}>
              <CountryIcon country={countryCode} />
              <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{countryCode}</Text>
            </View>
          }
          <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{phone || id}</Text>
          </View>

          <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            <View style={{padding: '5px', backgroundColor: '#3898D3', borderRadius: '2px', display: 'flex', alignItems: 'center'}}>
              <Text style={{fontFamily: 'pdfFont', color: '#ffff', backgroundColor: '#3898D3', fontWeight: 'bold', fontSize: '12px'}}>ID</Text>
            </View>
            <View style={{padding: '5px', display: 'flex', alignItems: 'center'}}>
              <Text style={{fontFamily: 'pdfFont', fontWeight: 'bold', fontSize: '12px'}}>{person?.id}</Text>
            </View>
          </View>

          <View style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px'}}>
            {
              person?.socialNetworks?.length > 0 && person.socialNetworks.map((el, index) => {
                return (
                  <Link key={index} src={SupportService.getSocialProfileUrl(el.socialNetwork,el.profile)}>
                    <SocialIcon network={el.socialNetwork} />
                  </Link>
                )
              })
            }
            {
              person?.sources?.length > 0 && person.sources.map((el, idx) => {
                return (
                  <View key={idx}><SocialIcon network={`${el}`} /></View>
                )
              })}
          </View>
        </View>



      </View>

      <View style={{padding: '20px 10px'}}>
        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', border: '1px solid #ececec', padding: '20px'}}>
          <View style={{width: '25%'}}>
            <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{SupportService.getStatNum(commonStatistics.friends || 0)} {t("friends")}</Text>
          </View>
          <View style={{width: '25%'}}>
            <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{SupportService.getStatNum(commonStatistics.likes || 0)} {t("likes")}</Text>
          </View>
          <View style={{width: '25%'}}>
            <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{SupportService.getStatNum(commonStatistics.mentions || 0)} {t("mentions")}</Text>
          </View>
          <View style={{width: '25%'}}>
            <Text style={{fontSize: '14px', fontFamily: 'pdfFont',}}>{SupportService.getStatNum(commonStatistics.posts || 0)} {t("posts")}</Text>
          </View>
        </View>
      </View>

    </View>
  );
};

export default withTranslation()(Person);